import React from "react";
import SeccionComparacion from "../components/secciones/seccion_comparacion";
import FooterTerminos from "../components/secciones/seccion_footer";

const Comparacion = () => {
  return (
    <div>
      <main>
        <section
          id="comparacion"
          className="effect-section skew-effect primary-after bg-gray-100 pb-5"
        >
          <SeccionComparacion />
        </section>
      </main>
      <FooterTerminos />
    </div>
  );
};

export default Comparacion;
