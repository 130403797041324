import React from 'react';
import TarjetaEmpresa from '../empresa/informacion_empresa'
import datosEmpresa from './datos'
import Logo from '../../images/logo_nuevo_da.png'

const Empresa = () =>{
    return(
        <div id="footer_empresa" className="container">
            <div className="row">
                <div className="col-lg-3 my-4">
                    <img src={Logo} alt="logo" />
                    <p className="mt-3 w-lg-80"><a href={datosEmpresa.empresa.titulo}>danalyticspro.co</a></p>
                    <div className="nav justify-content-center justify-content-md-start pt-1">
                        {datosEmpresa.empresa.redes.map(red_social =>(
                            <a key={red_social.id} className="icon icon-sm icon-primary rounded me-2" href={red_social.link}>
                                <i className={red_social.icono}></i>
                            </a>
                        ))}
                    </div>
                    <a href="https://www.sic.gov.co">www.sic.gov.co</a>
                </div>
               
                {datosEmpresa.informacion.map(bloque =>(
                    <TarjetaEmpresa informacion={bloque} key={bloque.id}/>
                ))}
               
            </div>
        </div>
    );
}

export default Empresa