import React from "react";
import { Row, Image } from "react-bootstrap";

import DatosComparacionForm from "../formularios/datos_pronostico";
import CajaDescripcion from "../cajas/caja_descripcion";
import logo from "../../images/logo.png";

const SeccionComparacion = () => {
  const titulo_pronostico = "";
  const descripcion_pronostico =  ""
  const info_adicional = "Para probar la herramienta diligencia los siguientes datos. Te mostraremos cuánto potencialmente pueden mejorar los pronósticos que realizas si comienzas a usar DAnalytics Forecast.";

  return (
    <div>
      <div className="white-shade-effect"></div>
      <div className="container position-relative z-index-9">
        <Row className="justify-content-center">
          <div className="text-center">
            <Image
              style={{ maxHeight: "120px", paddingBottom: "0" }}
              src={logo}
              fluid
            />
          </div>
          <CajaDescripcion
            titulo={titulo_pronostico}
            descripcion={descripcion_pronostico}
            info_adicional={info_adicional}
          ></CajaDescripcion>
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-xs-12 py-2 bg-white rounded shadow-style">
            <div className="text-center">
              <DatosComparacionForm></DatosComparacionForm>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default SeccionComparacion;
