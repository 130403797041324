import React from "react";

import SeccionResultados from "../components/secciones/seccion_resultados";
import FooterTerminos from "../components/secciones/seccion_footer";

const Resultados = () => {
  return (
    <div>
      <main>
        <section id="resultados" className="effect-section bg-gray">
          <SeccionResultados />
        </section>
      </main>
      <FooterTerminos />
    </div>
  );
};

export default Resultados;
