import { React, useState } from "react";
import { Row, Image, Col } from "react-bootstrap";

import DatosPronosticoClienteForm from "../formularios/datos_cliente";
import SeccionResultadosCliente from "./seccion_resultado_cliente";
import logo from "../../images/logo2.png";

const SeccionPronosticos = () => {
  const [values, setValues] = useState({});
  const [isEmpty, setEmpty] = useState(true);

  const handleCallback = (data) => {
    const datos = {...data}
    if (Object.keys(datos).length !== 0){
      setValues(datos);
      setEmpty(false);
    }
  };

  return (
    <div>
      <div className="white-shade-effect"></div>
      <div className="container-xl position-relative py-lg-2 z-index-9">
        <Row>
          <div>
            <Image
              style={{ maxHeight: "130px", paddingBottom: "0" }}
              src={logo}
              fluid
            />
          </div>
          <div className="col-12 py-2 bg-white rounded shadow-style">
            <div className="text-center">
              <Row className="justify-content-center">
                <Col lg={5} md={5} className="py-2 bg-white rounded">
                  <DatosPronosticoClienteForm
                    parentCallback={handleCallback}
                  ></DatosPronosticoClienteForm>{" "}
                </Col>
                <Col lg={7} md={7} className="py-2 bg-white rounded">
                  {isEmpty ? (
                    ""
                  ) : (
                    <SeccionResultadosCliente props={values}>
                    </SeccionResultadosCliente>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default SeccionPronosticos;
