import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, ListGroup, Spinner } from "react-bootstrap";

import {
  apiObtenerDatosPronosticos,
  apiSubirPronosticos,
} from "../../apis/api";
import plantilla from "../../docs/plantilla.xlsx";

const DatosComparacionForm = () => {
  const [values, setValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigation = useNavigate();

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const checkextension = (archivoPronostico) => {
    if (/\.(xlsx)$/i.test(archivoPronostico) === false) {
      return false;
    }
    return true;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    const allErrors = {};

    const archivoPronostico = values.archivo_pronostico;
    const first_name = values.name;
    const last_name = values.last_name;
    const email = values.email;

    const tieneExtensionValida = checkextension(archivoPronostico);

    if (tieneExtensionValida === false) {
      allErrors.archivo_pronostico = "¡La extensión es inválida!";
    }

    const isEmpty = Object.keys(allErrors).length === 0;

    if (!isEmpty) {
      setErrors(allErrors);
      setValidated(false);
      setLoading(false);
    } else {
      const archivo = event.target[4].files;
      var resultados = {};

      await apiSubirPronosticos(archivo[0]).then((response) =>
        console.log(response)
      );

      await apiObtenerDatosPronosticos(
        archivo[0].name,
        first_name,
        last_name,
        email
      ).then((response) => (resultados = response.data));
      setValidated(true);
      navigation("/resultados", { state: resultados });
    }
  };

  return (
    <Form onSubmit={submitHandler} validated={validated}>
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label>Nombres*</Form.Label>
        <Form.Control
          required
          name="name"
          type="text"
          onChange={onFormChange}
          placeholder="Ingresa tu nombre"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formLastName">
        <Form.Label>Apellidos*</Form.Label>
        <Form.Control
          required
          name="last_name"
          type="text"
          onChange={onFormChange}
          placeholder="Ingresa tus apellidos"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Correo corporativo*</Form.Label>
        <Form.Control
          required
          name="email"
          type="email"
          onChange={onFormChange}
          placeholder="Ingresa tu correo electrónico"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Whatsapp (Opcional)</Form.Label>
        <Form.Control
          name="whatsapp"
          type="text"
          onChange={onFormChange}
          placeholder="Ingresa tu Whatsapp"
        />
      </Form.Group>

      <Form.Group controlId="formFile">
        <Form.Text style={{ textAlign: "left" }}>
          <div className="text-left">
            Para conocer cuánto podrían mejorar tus pronósticos:
          </div>
        </Form.Text>
        <Form.Text>
          <ListGroup as="ol" variant="flush" style={{ textAlign: "left" }}>
            <ListGroup.Item as="li">
              1. Descarga la siguiente plantilla:{" "}
              <Link
                to={plantilla}
                target="_blank"
                download="plantilla_pronosticos"
              >
                Plantilla pronósticos
              </Link>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              2. Copia tus datos al Excel y guardarlos (solo copia la
              información de una serie, por ejemplo, las ventas de 1 producto, o
              1 marca, o 1 línea de negocio) Mínimo suministra 12 periodos.
            </ListGroup.Item>
            <ListGroup.Item as="li">3. Carga el archivo.</ListGroup.Item>
          </ListGroup>
        </Form.Text>

        <Form.Label>Seleccionar archivo de pronósticos*</Form.Label>
        <Form.Control
          required
          name="archivo_pronostico"
          type="file"
          isInvalid={!!errors.archivo_pronostico}
          onChange={onFormChange}
        />

        <Form.Control.Feedback type="invalid">
          Por favor, seleccione un archivo con extensión (.xlsx)
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="m-3" style={{ textAlign: "left" }}>
        <Form.Check
          required
          type="checkbox"
          label={
            <span>
                Acepto {''}
                <a href='https://www.danalyticspro.co/politica-de-privacidad-y-tratamiento-de-datos/' target="_blank" rel="noopener noreferrer">
                  política de tratamiento de datos personales*
                </a>
            </span>
        }
        />
        </Form.Group>
      <div className="mt-2">
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Cargando el pronóstico
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="boton-primario"
          >
            Conocer mejora del pronóstico
          </Button>
        )}
      </div>
    </Form>
  );
};

export default DatosComparacionForm;
