import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, ListGroup, Spinner } from "react-bootstrap";

import {
  apiObtenerDatosPrediccionCliente,
  apiSubirPronosticos,
} from "../../apis/api";
import plantilla from "../../docs/plantilla_cliente.xlsx";

const DatosPronosticoClienteForm = (props) => {
  const [values, setValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const checkextension = (archivoPronostico) => {
    if (/\.(xlsx)$/i.test(archivoPronostico) === false) {
      return false;
    }
    return true;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setLoading(true);

    const allErrors = {};

    const archivoPronostico = values.archivo_pronostico;
    const numeroPeriodos = values.numero_periodos;

    const tieneExtensionValida = checkextension(archivoPronostico);

    if (tieneExtensionValida === false) {
      allErrors.archivo_pronostico = "¡La extensión es inválida!";
    }

    if (numeroPeriodos <= 0) {
      allErrors.numeroPeriodos = "¡El número de periodos debe ser mayor a 0!";
    }

    const isEmpty = Object.keys(allErrors).length === 0;

    if (!isEmpty) {
      setErrors(allErrors);
      setValidated(false);
      setLoading(false);
    } else {
      const archivo = event.target[0].files;
      var resultados = {};

      await apiSubirPronosticos(archivo[0]).then((response) =>
        console.log("respuesta", response)
      );

      await apiObtenerDatosPrediccionCliente(
        archivo[0].name,
        numeroPeriodos
      ).then((response) => (resultados = response.data));

      setValidated(true);
      setLoading(false);

      props.parentCallback(resultados);
      event.preventDefault();
    }
  };

  return (
    <Form onSubmit={submitHandler} validated={validated}>
      <Form.Group controlId="formFile">
        <Form.Text style={{ textAlign: "left" }}>
          <div className="text-left">
            Para conocer cuánto podrían mejorar tus pronósticos:
          </div>
        </Form.Text>
        <Form.Text>
          <ListGroup as="ol" variant="flush" style={{ textAlign: "left" }}>
            <ListGroup.Item as="li">
              1. Descarga la siguiente plantilla:{" "}
              <Link
                to={plantilla}
                target="_blank"
                download="plantilla_pronosticos"
              >
                Plantilla pronósticos
              </Link>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              2. Copia tus datos al Excel y guardarlos (solo copia la
              información de una serie, por ejemplo, las ventas de 1 producto, o
              1 marca, o 1 línea de negocio) Mínimo suministra 12 periodos.
            </ListGroup.Item>
            <ListGroup.Item as="li">3. Carga el archivo.</ListGroup.Item>
          </ListGroup>
        </Form.Text>

        <Form.Label>Seleccionar archivo de pronósticos*</Form.Label>
        <Form.Control
          required
          name="archivo_pronostico"
          type="file"
          isInvalid={!!errors.archivo_pronostico}
          onChange={onFormChange}
        />

        <Form.Control.Feedback type="invalid">
          Por favor, seleccione un archivo con extensión (.xlsx)
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Número de meses a pronosticar</Form.Label>
        <Form.Control
          required
          name="numero_periodos"
          type="number"
          placeholder="Número de meses a pronosticar"
          isInvalid={!!errors.numeroPeriodos}
          onChange={onFormChange}
        />
        <Form.Control.Feedback type="invalid">
          {errors.numeroPeriodos}
        </Form.Control.Feedback>
      </Form.Group>

      <div className="mt-3">
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Cargando el pronóstico
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            style={{ backgroundColor: "#ffc012", borderColor: "#ffc012" }}
          >
            Realizar Pronósticos
          </Button>
        )}
      </div>
    </Form>
  );
};

export default DatosPronosticoClienteForm;
