import React from "react";
import SeccionInicio from "../components/secciones/seccion_inicio";
import FooterTerminos from "../components/secciones/seccion_footer";

const Landing = () => {
  return (
    <div>
      <main>
        <SeccionInicio />
      </main>
      <FooterTerminos />
    </div>
  );
};

export default Landing;
