import React from 'react';
import { Routes, Route } from 'react-router-dom'

import Landing from '../pages/landing';
import Comparacion from '../pages/comparacion'
import Resultados from '../pages/resultados';
import Pronosticos from '../pages/pronosticos';
import NotFound from '../pages/notFound';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/comparacion" element={<Comparacion />} />
      <Route path="/resultados" element={<Resultados />} />
      <Route path="/pronosticos/:id" element={<Pronosticos />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
