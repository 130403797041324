import React from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SeccionResultados = () => {
  const { state } = useLocation();

  var valor_actual = state.metodo_actual.valor_rmse;
  var valor_metodo_1 = state.metodo_1.valor_rmse;
  var listado_periodos = state.listado_periodos;
  var resultado = 0;

  if (valor_actual > valor_metodo_1) {
    resultado = (
      ((valor_actual - valor_metodo_1) / valor_actual) *
      100
    ).toFixed(2);
  }

  const line_chart_data = {
    labels: listado_periodos,
    datasets: [
      {
        label: "Método Actual",
        data: state.metodo_actual.listado_errores,
        fill: false,
        borderColor: "#0b0cb5",
        backgroundColor: "#0b0cb5",
      },
      {
        label: "Método 1",
        data: state.metodo_1.listado_errores,
        fill: false,
        borderColor: "#ffa01a",
        backgroundColor: "#ffa01a",
      },
    ],
  };

  const opciones = {
    plugins: {
      title: {
        display: true,
        align: "center",
        text: "Gráfica del error de pronóstico",
        font: {
          size: 30,
        },
      },
      legend: {
        display: true,
        position: "left",
      },
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "Error",
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Período",
        },
      },
    },
  };

  return (
    <div>
      <div className="white-shade-effect"></div>
      <Container className="position-relative py-lg-8 z-index-9">
        <Row className="justify-content-center">
          <Col lg={6} md={6} className="py-2 bg-white rounded">
            <Line
              width={400}
              height={300}
              data={line_chart_data}
              options={opciones}
            />
          </Col>
        </Row>
        <Row className="py-3 justify-content-center">
          <Col lg={6} md={6} className="py-2 bg-white rounded shadow-style">
            <h3 className="text-center">Medida del error de pronóstico</h3>
            <p>
              En este caso utilizamos la raíz del error cuadrático medio (RMSE
              por sus siglas en inglés) para evaluar cuál método es el mejor.
              Entre menor sea el valor, mejor es el método{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Este ejercicio gratuito es una aproximación a la mejora que
                    podría tener la precisión del pronóstico realizando un
                    pronóstico en la muestra (in sample).
                  </Tooltip>
                }
              >
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </OverlayTrigger>
            </p>
            <Table responsive>
              <thead>
                <tr>
                  <th>Error actual</th>
                  <th>Método 1</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{valor_actual}</td>
                  <td>{valor_metodo_1}</td>
                </tr>
              </tbody>
            </Table>
            <Row className="py-2 justify-content-center">
              <p className="text-center">
                <h3>
                  ¿Te interesaría reducir el error de tus pronósticos en mínimo
                  un {resultado}%?
                </h3>
              </p>
            </Row>
          </Col>
        </Row>
        <div className="mt-1 text-center">
          <a
            href="https://meetings.hubspot.com/destrada1"
            className="btn btn-primary"
            style={{ backgroundColor: "#ffc012", borderColor: "#ffc012" }}
          >
            Agendar una cita
          </a>
        </div>
      </Container>
    </div>
  );
};

export default SeccionResultados;
