import React from "react";

const CajaDescripcion = (props) => {
  return (
    <div className="col-lg-12 col-xl-10 text-center text-white p-0">
      <h2 className="display-7 pb-2">{props.titulo}</h2>
      <h1>{props.descripcion}</h1>
      <h4>{props.info_adicional}</h4>
      <br />
    </div>
  );
};

export default CajaDescripcion;
