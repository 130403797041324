import React from "react";

import Empresa from "../../components/empresa/empresa"


const FooterTerminos = () => {
  return (
    <footer className="bg-gray-100 footer">
      <div className="footer-top">
        <Empresa />
      </div>
      <div className="footer-bottom border-top py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 my-1">
              <ul className="nav justify-content-center justify-content-md-start dark-link small footer-link-1">
                <li className="me-3">
                  <a href="/#">Términos y condiciones de uso</a>
                </li>
                <li className="me-3">
                  <a href="/#">Política de privacidad y tratamiento de datos</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 my-1 text-center text-md-end small">
              <p className="m-0">
                © 2021 Copyright{" "}
                <a href="#home" className="text-reset">
                  DAnalytics SAS
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTerminos;
