import React from "react";

import SeccionPronosticos from "../components/secciones/seccion_pronostico";
import FooterTerminos from "../components/secciones/seccion_footer";
import { Navigate, useParams } from "react-router-dom";

const Pronosticos = () => {
  const parametros = useParams();
  console.log(parametros);
  if (parametros.id === '10-174A') {
    return (
      <div>
        <main>
          <section
            id="comparacion"
            className="effect-section bg-gray-100 "
          >
            <SeccionPronosticos />
          </section>
        </main>
        <FooterTerminos />
      </div>
    );
  }else{
    return <Navigate to="/" replace />;
  }
};

export default Pronosticos;
