import React from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

Chart.register(...registerables);

const SeccionResultadosCliente = (props) => {
  const state = props.props;
  var valores_actuales = state.listado_valores_reales;

  var valores_prediccion_metodo_1 = state.predicciones_metodo_1.resultado_prediccion;
  var valores_prediccion_metodo_2 = state.predicciones_metodo_2.resultado_prediccion;

  var listado_periodos = state.listado_periodos;
  var listado_periodos_pronosticados = state.periodos_pronosticados.periodos_pronosticados;

  var resultados_metodo_1 = valores_actuales.concat(valores_prediccion_metodo_1);
  var resultados_metodo_2 = valores_actuales.concat(valores_prediccion_metodo_2);

  const line_chart_data = {
    labels: listado_periodos,
    datasets: [
      {
        label: "Valores Actuales",
        data: valores_actuales,
        fill: false,
        borderColor: "#0b0cb5",
        backgroundColor: "#0b0cb5",
      },
      {
        label: "Valores pronosticados metodo 1",
        data: resultados_metodo_1,
        fill: false,
        borderColor: "#ffa01a",
        backgroundColor: "#ffa01a",
      },
      {
        label: "Valores pronosticados metodo 2",
        data: resultados_metodo_2,
        fill: false,
        borderColor: "#2264ff",
        backgroundColor: "#2264ff",
      },
    ],
  };

  const opciones = {
    plugins: {
      title: {
        display: true,
        align: "center",
        text: "Gráfica del pronóstico",
        font: {
          size: 30,
        },
      },
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: "Valor",
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Período",
        },
      },
    },
  };

  return (
    <div>
      <div className="white-shade-effect"></div>
      <Container className="position-relative z-index-9">
        <Row className="justify-content-center">
          <Col lg={8} md={8} className="bg-white rounded">
            <Line
              width={300}
              height={300}
              data={line_chart_data}
              options={opciones}
            />
          </Col>
        </Row>
        <Row className="py-3 justify-content-center">
          <Col lg={8} md={8} className="py-2 bg-white rounded shadow-style">
            <h3 className="text-center">Tabla de pronósticos</h3>
            <p></p>
            <ReactHTMLTableToExcel
              id="table-to-excel"
              className="btn btn-sm btn-secondary"
              table="table-pronosticos"
              filename="pronosticos"
              sheet="pronosticos"
              buttonText="Exportar a excel"
            />
            <Table id="table-pronosticos" responsive>
              <thead>
                <tr>
                  <th>Período</th>
                  <th>Valor</th>
                  <th>Método</th>
                </tr>
              </thead>
              <tbody>
                {listado_periodos_pronosticados.map((item, index) => {
                  return <tr>
                  <td>{item}</td>
                  <td>{valores_prediccion_metodo_1[index]}</td>
                  <td>Método 1</td>
                </tr>
                })}
                {listado_periodos_pronosticados.map((item, index) => {
                  return <tr>
                  <td>{item}</td>
                  <td>{valores_prediccion_metodo_2[index]}</td>
                  <td>Método 2</td>
                </tr>
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SeccionResultadosCliente;
