import axios from "axios";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

export async function apiObtenerDatosPronosticos(nombre_archivo, first_name="", last_name="", email="") {
  const bucket_name = process.env.REACT_APP_BUCKET_NAME;

  const api_error = process.env.REACT_APP_API_CALL_ERROR;

  console.log(bucket_name, api_error)

  const response = await axios.get(
    api_error,
    {
      params: {
        bucket: bucket_name,
        nombre_archivo: nombre_archivo,
        first_name:first_name,
        last_name:last_name,
        email:email
      },
    }
  );

  return response;
}

export async function apiObtenerDatosPrediccionCliente(nombre_archivo, numero_periodos) {
  const bucket_name = process.env.REACT_APP_BUCKET_NAME;

  const api = process.env.REACT_APP_API_CALL_PREDICTION;

  const response = await axios.get(
    api,
    {
      params: {
        bucket: bucket_name,
        nombre_archivo: nombre_archivo,
        numero_periodos:numero_periodos,
      },
    }
  );

  return response;
}


export async function apiSubirPronosticos(file) {
  const bucket_name = process.env.REACT_APP_BUCKET_NAME

  const target = { Bucket: bucket_name, Key: file.name, Body: file };

  const accessKeyId = process.env.REACT_APP_ACCESS_S3_KEY
  const secretAccessKey = process.env.REACT_APP_ACCESS_S3_SECRET
  const s3Region = process.env.REACT_APP_S3_REGION
  const creds = {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  };

  try {
    const parallelUploads3 = new Upload({
      client: new S3Client({ region: s3Region, credentials: creds }),
      leavePartsOnError: false,
      params: target,
    });

    parallelUploads3.on("httpUploadProgress", (progress) => {
      console.log(progress);
    });

    parallelUploads3.done();
  } catch (e) {
    console.log("error", e);
  }

  return true;
}
